export default [
  {
    path: '/company/login',
    name: 'company-login',
    component: () => import('@/views/construction-company/CompanyLogin.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/company/reset/password',
    name: 'company-reset-password',
    component: () => import('@/views/construction-company/CompanyAlterPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/company/recover/password/succeed',
    name: 'company-recover-password-succeed',
    component: () => import('@/views/construction-company/CompanyAlterMessage.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/company/register',
    name: 'company-register',
    component: () => import('@/views/construction-company/CompanyRegister.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/company/registered',
    name: 'company-registered',
    component: () => import('@/views/construction-company/CompanyRegisterSuccess.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/company/account/activate-email',
    name: 'company-account-activate-email',
    component: () => import('@/views/construction-company/CompanyActivateEmail.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/company/immobile/register',
    name: 'company-immobile-register',
    component: () => import('@/views/construction-company/immobile/ImmobileRegister.vue'),
    meta: {
      pageTitle: 'Imóvel',
      breadcrumb: [
        {
          text: 'Cadastrar Imóvel',
          active: true,
        },
      ],
      requiresAuth: false,
    },
  },
  {
    path: '/company/immobile/view',
    name: 'company-immobile-view',
    component: () => import('@/views/construction-company/immobile/ImmobileRegister.vue'),
    meta: {
      pageTitle: 'Imóvel',
      breadcrumb: [
        {
          text: 'Editar Imóvel',
          active: true,
        },
      ],
      requiresAuth: false,
    },
  },
  {
    path: '/company/immobile/list',
    name: 'company-immobile-list',
    component: () => import('@/views/construction-company/CompanyImmobile.vue'),
    meta: {
      pageTitle: 'Imóveis',
      breadcrumb: [
        {
          text: 'Lista de Imóveis',
          active: true,
        },
      ],
      requiresAuth: false,
    },
  },
]
