import Vue from 'vue'
import VueRouter from 'vue-router'

import siteRouter from '@/views/site/router'
import companyRouter from '@/views/construction-company/router'

Vue.use(VueRouter)
const roles = ['Admin']
const rolesMarketing = ['Admin', 'MARKETING']
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...siteRouter,
    ...companyRouter,
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        logo: 'show',
        search: 'hide',
      },
    },
    {
      path: '/investors/list',
      name: 'investors-list',
      component: () => import('@/views/investors/Investors.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Lista de Investidores',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/login/profile',
      name: 'login-profile',
      component: () => import('@/views/LoginProfile.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register/profile',
      name: 'register-profile',
      component: () => import('@/views/RegisterProfile.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/admin/adm-login',
      name: 'admin-login',
      component: () => import('@/views/admin/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/admin/account/activate-email',
      name: 'admin-account-activate-email',
      component: () => import('@/views/admin/ActivateEmail.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/list',
      name: 'broker-list',
      component: () => import('@/views/BrokerListView.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Lista de Corretores',
            active: true,
          },
        ],
        role: roles,
      },
    },
    {
      path: '/broker/register',
      name: 'broker-register',
      component: () => import('@/views/broker/BrokerRegister.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/registered',
      name: 'broker-registered',
      component: () => import('@/views/broker/BrokerRegisterSuccess.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/registered/true',
      name: 'broker-registered-true',
      component: () => import('@/views/broker/BrokerRegisterTrue.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/registered/analysis',
      name: 'broker-registered-analysis',
      component: () => import('@/views/broker/BrokerRegisterValidation.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/account/activate-email',
      name: 'broker-account-activate-email',
      component: () => import('@/views/broker/BrokerActivateEmail.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/login',
      name: 'broker-login',
      component: () => import('@/views/broker/BrokerLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/account/',
      name: 'broker-account',
      component: () => import('@/views/BrokerEdditingAccount.vue'),
      meta: {
        logo: 'show',
        search: 'hide',
        pageTitle: 'Corretor',
        breadcrumb: [
          {
            text: 'Validar Cadastro',
            active: true,
          },
        ],
      },
    },
    {
      path: '/broker/reset/password',
      name: 'broker-reset-password',
      component: () => import('@/views/broker/BrokerAlterPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/new',
      name: 'broker-new',
      component: () => import('@/views/BrokerFormNew.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Novo Corretor',
            active: true,
          },
        ],
      },
    },
    {
      path: '/recover/password',
      name: 'recuperar-senha',
      component: () => import('@/views/AlterarSenha.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/recover/password/succeed',
      name: 'broker-recover-password-succeed',
      component: () => import('@/views/broker/BrokerAlterMessage.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/broker/register/analysis',
      name: 'broker-register-analysis',
      component: () => import('@/views/broker/BrokerRegisterAnalysis.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recover/password/pt2',
      name: 'recuperar-senha-pt_2',
      component: () => import('@/views/AlterarSenha_pt2.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recover/password/pt3',
      name: 'recuperar-senha-pt_3',
      component: () => import('@/views/AlterarSenha_pt3.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recover/password/succeed',
      name: 'recover-password-succeed',
      component: () => import('@/views/ResetPasswordMessageSend.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset/password/success',
      name: 'reset-password-seccess',
      component: () => import('@/views/ResetPasswordMessageSuccess.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/company/list',
      name: 'company-list',
      component: () => import('@/views/construction-company/Company.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Lista de Construtoras',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/deliberation/company/list',
      name: 'deliberation-company-list',
      component: () => import('@/views/construction-company/CompanyDeliberation.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Lista de Construtoras',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/deliberation',
      name: 'deliberation',
      component: () => import('@/views/Deliberation.vue'),
      meta: {
        pageTitle: 'Deliberação',
        breadcrumb: [
          {
            text: 'Deliberação',
            active: true,
          },
        ],
        requiresAuth: true,
        role: roles,
      },
    },
    {
      path: '/deliberation-detailed',
      name: 'deliberation-detailed-old',
      component: () => import('@/views/DeliberationDetailed.vue'),
      meta: {
        pageTitle: 'Deliberação',
        breadcrumb: [
          {
            text: 'Deliberação',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/manager-quotas',
      name: 'manager-quotas',
      component: () => import('@/views/admin/investment/ListInvestment.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Gestão de Cotas e Comissões',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: roles,
      },
    },
    {
      path: '/manager-quotas-details/:id',
      name: 'manager-quotas-details',
      component: () => import('@/views/admin/investment/ManagerInvestment.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Detalhes do Investimento',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: roles,
      },
    },
    {
      path: '/list-cancell-investment',
      name: 'list-cancell-investment',
      component: () => import('@/views/admin/cancelation/ListCancelationInvestment.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Cancelamento de Investimentos',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: roles,
      },
    },
    {
      path: '/manager-cancell-investment/:id',
      name: 'manager-cancell-investment',
      component: () => import('@/views/admin/cancelation/ManagerCancelationInvestment.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Detalhes do Investimento',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: roles,
      },
    },
    {
      path: '/deliberation/detailed',
      name: 'deliberation-detailed',
      component: () => import('@/views/DeliberationBroker.vue'),
      props: route => ({ query: route.query.id }),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Detalhamento',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: roles,
      },
    },
    {
      path: '/user/list',
      name: 'user-list',
      component: () => import('@/views/GoodTable.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Lista de Usuários',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/list',
      name: 'admin-list',
      component: () => import('@/views/GoodTable.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Lista de Usuários',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/account/user/editing',
      name: 'editing-user-data',
      component: () => import('@/views/account-editing/AccountSetting.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Dados Pessoais',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
      },
    },
    {
      path: '/account/setting',
      name: 'dados-user',
      component: () => import('@/views/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Dados Pessoais',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: '/account/reset-password',
      name: 'alterar.senha',
      component: () => import('@/views/AlterarSenha_pt3.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        requiresAuth: true,
        // navbar: {
        //   // ? For horizontal menu, navbar type will work for navMenu type
        //   type: 'hidden',
        // },
      },
    },
    {
      path: '/quiz',
      name: 'quiz',
      component: () => import('@/views/FormWizard.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        bg: 'body_img',
        requiresAuth: true,
      },
    },
    {
      path: '/quiz/make',
      name: 'quiz-make',
      component: () => import('@/views/form-repeater/FormRepeater.vue'),
      meta: {
        quiz: 'hide',
        logo: 'hide',
        search: 'show',
        bg: 'body_img',
        requiresAuth: true,
      },
    },
    {
      path: '/quiz/first',
      name: 'quiz-first',
      component: () => import('@/views/QuizFirst.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/investments/user/pending',
      name: 'investments-user-pending',
      component: () => import('@/views/investor/ListInvestmentUserPending.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/investments/broker-commission',
      name: 'broker-commissions',
      component: () => import('@/views/broker/CommissionList.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/investments/broker-immobiles',
      name: 'broker-immobiles',
      component: () => import('@/views/broker/ImmobileList.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/investments/project-detail/:id/:nameProfile',
      name: 'user-project-detail',
      component: () => import('@/views/project/ProjectDetail.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/investments/user/progress',
      name: 'investments-user-progress',
      component: () => import('@/views/investor/ListInvestmentUserInProgress.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/investments/user/closed',
      name: 'investments-user-closed',
      component: () => import('@/views/investor/ListInvestmentUserClosed.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/investments/user/sold',
      name: 'investments-user-sold',
      component: () => import('@/views/investor/ListInvestmentUserSold.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/quiz/last',
      name: 'quiz-last',
      component: () => import('@/views/QuizLast.vue'),
      meta: {
        quiz: 'hide',
        logo: 'show',
        search: 'hide',
        requiresAuth: true,
      },
    },
    {
      path: '/quiz/control',
      name: 'quiz-control',
      component: () => import('@/views/QuizControl.vue'),
      meta: {
        pageTitle: 'Questionario',
        breadcrumb: [
          {
            text: 'Lista de Perguntas',
            active: true,
          },
        ],
        requiresAuth: true,
        role: roles,
      },
    },
    {
      path: '/configuration/talk-specialist',
      name: 'talk-specialist',
      component: () => import('@/views/admin/configuration/TalkSpecialist.vue'),
      meta: {
        pageTitle: 'Configurações',
        breadcrumb: [
          {
            text: 'Configurar Número Especialista',
            active: true,
          },
        ],
        requiresAuth: true,
        role: roles,
      },
    },
    {
      path: '/register',
      name: 'registro',
      component: () => import('@/views/Registro.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/registered',
      name: 'registered',
      component: () => import('@/views/Registered.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/account/confirm-email',
      name: 'confirm-email',
      component: () => import('@/views/ActivateEmail.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/notification/register',
      name: 'notification-register',
      component: () => import('@/views/Notification.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Notificação',
            active: true,
          },
        ],
        requiresAuth: true,
        role: roles,
      },
    },
    {
      path: '/notification/list',
      name: 'notification-list',
      component: () => import('@/views/NotificationList.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Notificação',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/notification/my/list',
      name: 'notification-list-user',
      component: () => import('@/views/NotificationListUser.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Notificação',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/notification/message/:id/:notificationUserId',
      name: 'notification-message',
      component: () => import('@/views/notification/NotificationMessage.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Notificação',
            active: true,
          },
        ],
        requiresAuth: true,
        back_notification: true,
      },
    },
    {
      path: '/notification/send',
      name: 'notification-send',
      component: () => import('@/views/notification/NotificationSend.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Notificação',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: roles,
      },
    },
    {
      path: '/faq-admin',
      name: 'faq-admin',
      component: () => import('@/views/pages/faq/FaqPage.vue'),
      meta: {
        pageTitle: 'FAQ',
        breadcrumb: [
          {
            text: 'FAQ',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: roles,
      },
    },
    {
      path: '/blog-admin',
      name: 'blog-admin',
      component: () => import('@/views/pages/blog/BlogPage.vue'),
      meta: {
        pageTitle: 'Blog',
        breadcrumb: [
          {
            text: 'Blog',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
        role: rolesMarketing,
      },
    },
    {
      path: '/checkout/:id',
      name: 'opportunity-checkout',
      component: () => import('@/views/checkout-oppotunity/CheckoutOpportunity.vue'),
    },
    {
      path: '/checkout',
      name: 'opportunity-checkout',
      component: () => import('@/views/checkout-oppotunity/CheckoutOpportunity.vue'),
      meta: {
        pageTitle: 'Investir',
        breadcrumb: [
          {
            text: 'Investir',
            active: true,
          },
        ],
        requiresAuth: true,
        back: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-401',
      name: 'error-401',
      component: () => import('@/views/error/Error401.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/new/page',
      name: 'new-page',
      component: () => import('@/views/pagefile/new.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/edit-imovel',
      name: 'edit-imovel',
      component: () => import('@/views/EditImovel.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Dados do Imóvel',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: '/publicacao-imovel',
      name: 'publicacao-imovel',
      component: () => import('@/views/PublicacaoImovel.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Pubicação do Imóvel',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: '/deliberation/immobile',
      name: 'deliberation-immobile',
      component: () => import('@/views/DeliberacaoImovel.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Deliberação do Imóvel',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: '/adm/public/immobile',
      name: 'adm-public-immobile',
      component: () => import('@/views/PublicImnobile.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Pubicação do Imóvel',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: '/adm/project/edit',
      name: 'project-edit',
      component: () => import('@/views/FormProject.vue'),
      meta: {
        pageTitle: 'Projecto/Obra',
        breadcrumb: [
          {
            text: 'Projecto/Obra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/adm/project/new',
      name: 'project-new',
      component: () => import('@/views/FormProject.vue'),
      meta: {
        pageTitle: 'Projeto/Obra',
        breadcrumb: [
          {
            text: 'Projeto/Obra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/adm/project',
      name: 'project',
      component: () => import('@/views/ProjectList.vue'),
      meta: {
        pageTitle: 'Projeto/Obra',
        breadcrumb: [
          {
            text: 'Projeto/Obra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/company/account',
      name: 'company-account',
      component: () => import('@/views/EditConstrutora.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Dados da Construtora',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/deliberation/company',
      name: 'deliberation-company',
      component: () => import('@/views/ConstrutoraAccountV.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Dados da Construtora',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: '/company/view/file',
      name: 'company-view-file',
      component: () => import('@/views/pagefile/ViewFileCompany.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/company/account/details',
      name: 'company-account-details',
      component: () => import('@/views/ConstrutoraAccountV.vue'),
      meta: {
        pageTitle: 'Painel',
        breadcrumb: [
          {
            text: 'Dados da Construtora',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },

    {
      path: '/adm/company/immobile/list',
      name: 'adm-company-immobile-list',
      component: () => import('@/views/admin/immobile/CompanyImmobile.vue'),
      meta: {
        pageTitle: 'Imóveis',
        breadcrumb: [
          {
            text: 'Lista de Imóveis',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: '/adm/company/immobile/list',
      name: 'adm-company-immobile-deliberation-list',
      component: () => import('@/views/admin/immobile/CompanyImmobileDeliberation.vue'),
      meta: {
        pageTitle: 'Imóveis',
        breadcrumb: [
          {
            text: 'Deliberação de Imóveis',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },

    {
      path: '/adm/company/immobile/public/list',
      name: 'adm-company-immobile-public-list',
      component: () => import('@/views/admin/immobile/CompanyImmobilePublic.vue'),
      meta: {
        pageTitle: 'Imóveis',
        breadcrumb: [
          {
            text: 'Publicação de Imóveis',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('accessToken') === null) {
      next({
        path: '/login/profile',
        params: { nextUrl: to.fullPath },
      })
    } else if (localStorage.getItem('role') === 'PRE_BROKER') {
      if (to.matched.some(record => record.meta.is_user)) {
        next({ name: 'broker-account' })
      } else {
        const roleUser = [localStorage.getItem('role')]
        const roleSystem = to.meta.role
        if (roleSystem !== undefined) {
          if (roleUser.some(v => roleSystem.indexOf(v) !== -1) === true) {
            next()
          } else {
            next({ name: 'error-401' })
          }
        } else {
          next()
        }
      }
    } else if (localStorage.getItem('role') !== 'PRE_BROKER') {
      if (to.matched.some(record => record.meta.is_user)) {
        next({ name: 'dashboard' })
      } else {
        const roleUser = [localStorage.getItem('role')]
        const roleSystem = to.meta.role
        if (roleSystem !== undefined) {
          if (roleUser.some(v => roleSystem.indexOf(v) !== -1) === true) {
            next()
          } else {
            next({ name: 'error-401' })
          }
        } else {
          next()
        }
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('accessToken') === null) {
      next()
    } else {
      next({ name: 'dashboard' })
    }
  } else {
    next()
  }
})

export default router
