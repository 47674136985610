import BlogService from '@/services/BlogService'
import Imoveis from '../services'

export default {
  namespaced: true,

  state: {
    slideImoveis: [],
    colorAccent: '#F8AC18',
  },

  actions: {
    obterImoveis() {
      return Imoveis.obterImoveis()
    },

    async obterBlogs() {
      const retorno = (await BlogService.getItemListPublic('MaxResultCount=3')).data.result.items.map(m => ({ ...m.blogItem, blogCategoryName: m.blogCategoryName, blogStatusName: m.blogStatusName }))
      const listRetorno = []
      retorno.forEach(async item => {
        const i = item
        const binary = (await BlogService.getBinary(i.coverPhotoId)).data.result.appBinaryObjects
        i.image = `data:${binary.dataType};base64,${binary.bytes}`
        listRetorno.push(i)
      })
      return listRetorno
    },

    obterMaterias() {
      return Imoveis.obterMaterias()
    },

    obterPhotos() {
      return Imoveis.obterPhotos()
    },

    obterTestemunhos() {
      return Imoveis.obterTestemunhos()
    },
  },
}
