export default [
  {
    path: '/',
    name: 'site-home',
    meta: { isSite: true },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/faq',
    name: 'site-faq',
    meta: { isSite: true },
    component: () => import('../views/Faq.vue'),
  },
  {
    path: '/blog/:categ',
    name: 'site-blog',
    meta: { isSite: true },
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/blog',
    name: 'site-blog',
    meta: { isSite: true },
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/blog-view/:id',
    name: 'site-blog-view',
    meta: { isSite: true },
    component: () => import('../views/BlogView.vue'),
  },
  {
    path: '/about',
    name: 'site-about',
    meta: { isSite: true },
    component: () => import('../views/About.vue'),
  },
  {
    path: '/corretor',
    name: 'site-corretor',
    meta: { isSite: true },
    component: () => import('../views/Corretor.vue'),
  },
  {
    path: '/construtora',
    name: 'site-construtora',
    meta: { isSite: true },
    component: () => import('../views/Construtora.vue'),
  },
  {
    path: '/privacy',
    name: 'site-privacy',
    meta: { isSite: true },
    component: () => import('../views/Privacy.vue'),
  },
  {
    path: '/terms',
    name: 'site-termos',
    meta: { isSite: true },
    component: () => import('../views/TermsPolicy.vue'),
  },
  {
    path: '/opportunity',
    name: 'site-oportunity',
    meta: { isSite: true },
    component: () => import('../views/Opportunity.vue'),
  },
  {
    path: '/opportunity/:id',
    name: 'site-oportunity-detail',
    meta: { isSite: true },
    component: () => import('../views/OpportunityDetail.vue'),
  },
  {
    path: '/opportunity/:id/:brokerUserId',
    name: 'site-oportunity-detail',
    meta: { isSite: true },
    component: () => import('../views/OpportunityDetail.vue'),
  },
  {
    path: '/calc-investment',
    name: 'site-calc-invest',
    meta: { isSite: true },
    component: () => import('../views/CalcInvestment.vue'),
  },
]
