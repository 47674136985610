import Vue from 'vue'

const BlogService = {
  // BINARY

  getBinary(id) {
    return Vue.prototype.$http.get(`/api/services/app/AppBinaryObjects/GetAppBinaryObjectsForEdit?Id=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },

  // Blog CATEGORY

  getCategoryList() {
    return Vue.prototype.$http.get('/api/services/app/BlogCategories/GetAll')
  },

  setCategory(item) {
    return Vue.prototype.$http.post('/api/services/app/BlogCategories/CreateOrEdit', item)
  },

  getCategory(id) {
    return Vue.prototype.$http.get(`/api/services/app/BlogCategories/GetBlogCategoryForEdit?id=${id}`)
  },

  delCategory(id) {
    return Vue.prototype.$http.delete(`/api/services/app/BlogCategories/Delete?id=${id}`)
  },

  // Blog STATUS

  getStatusList() {
    return Vue.prototype.$http.get('/api/services/app/BlogStatuses/GetAll')
  },

  setStatus(item) {
    return Vue.prototype.$http.post('/api/services/app/BlogStatuses/CreateOrEdit', item)
  },

  getStatus(id) {
    return Vue.prototype.$http.get(`/api/services/app/BlogStatuses/GetBlogStatusForEdit?id=${id}`)
  },

  delStatus(id) {
    return Vue.prototype.$http.delete(`/api/services/app/BlogStatuses/Delete?id=${id}`)
  },

  // Blog ITEM

  searchItems(term = null) {
    return Vue.prototype.$http.get(`/api/services/app/BlogItems/GetAll?Filter=${encodeURIComponent(term)}`)
  },

  getItemListPublic(params = '') {
    return Vue.prototype.$http.get(`/api/services/app/BlogItems/GetAllPublic?${params}`)
  },

  getItemList(params = '') {
    return Vue.prototype.$http.get(`/api/services/app/BlogItems/GetAll?${params}`)
  },

  setItem(item) {
    return Vue.prototype.$http.post('/api/services/app/BlogItems/CreateOrEdit', item)
  },

  getItem(id) {
    return Vue.prototype.$http.get(`/api/services/app/BlogItems/GetBlogItemForEdit?id=${id}`)
  },

  delItem(id) {
    return Vue.prototype.$http.delete(`/api/services/app/BlogItems/Delete?id=${id}`)
  },

  getContentBody(id) {
    return Vue.prototype.$http.get(`/api/services/app/BlogItems/GetBlogItemBody?Id=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },
}

export default BlogService
