export default {
  state: {
    data: {
      // roles: [{ roleName: 'broker' }],
    },
    isDocPending: false,
  },
  mutations: {
    setIsDocPending(state, payload) {
      state.isDocPending = payload
    },

    setUserData(state, payload) {
      state.data = payload
    },
  },
}
