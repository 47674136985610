import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios'
import VueMask from 'v-mask'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

axios.create()

axios.interceptors.request.use(config => {
  if (store.state.auth) {
    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = `Bearer ${store.state.token}`
  }
  return config
}, error =>
// Do something with request error
  Promise.reject(error))
axios.interceptors.response.use(response => response, error => {
  const originalRequest = error.config
  // eslint-disable-next-line no-underscore-dangle
  if (error.response.status === 401 && !originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true
    axios.post('/api/TokenAuth/RefreshToken', null).then(data => {
      store.dispatch('authfalse')
      store.dispatch('authtruth', data.data.result.items)
      originalRequest.headers.Authorization = `Bearer ${store.state.accessToken}`
      return axios(originalRequest)
    })
  }
  // Do something with response error
  return Promise.reject(error)
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueMask)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// iconnes
require('@core/assets/fonts/feather/iconfont.css')

// site style
require('@/views/site/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
