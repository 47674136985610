export default {
  obterImoveis() {
    return new Promise(res => {
      res([
        {
          imagem: 'https://1.bp.blogspot.com/-olA6V52d40A/TgDeJcnk5oI/AAAAAAAAA0k/pHH6WHBagSk/s1600/condominio2.jpg',
          local: { text: 'Balneário - SC', url: '/site' },
          tamanho: 130,
          titulo: 'Terraço Del Mare',
          descricao: 'Apartamento mobiliado alocado em frente ao mar',
          rentabilidade: 12,
          entrega: 'Outubro/2023',
          valor: 4500,
          url: '/site',
        },
        {
          imagem: 'https://www.takeda.arq.br/wp-content/uploads/2015/10/163-IMAGEM_FACHADA_DIURNA_R12_HR-R00.jpg',
          local: { text: 'Canoa - RS', url: '/site' },
          tamanho: 130,
          titulo: 'Terraço Del Mare',
          descricao: 'Apartamento mobiliado alocado em frente ao mar',
          rentabilidade: 12,
          entrega: 'Outubro/2023',
          valor: 4500,
          url: '/site',
        },
        {
          imagem: 'https://www.djc.com/stories/images/20190531/supermanbuilding1_big.jpg',
          local: { text: 'Canoa - RS', url: '/site' },
          tamanho: 130,
          titulo: 'Terraço Del Mare',
          descricao: 'Apartamento mobiliado alocado em frente ao mar',
          rentabilidade: 12,
          entrega: 'Outubro/2023',
          valor: 4500,
          url: '/site',
        },
        {
          imagem: 'https://www.djc.com/stories/images/20190531/supermanbuilding1_big.jpg',
          local: { text: 'Canoa - RS', url: '/site' },
          tamanho: 130,
          titulo: 'Terraço Del Mare',
          descricao: 'Apartamento mobiliado alocado em frente ao mar',
          rentabilidade: 12,
          entrega: 'Outubro/2023',
          valor: 4500,
          url: '/site',
        },
      ])
    })
  },

  obterBlogs() {
    return new Promise(res => {
      res([
        {
          imagem: 'https://2.bp.blogspot.com/-6FTH4q4m93U/XL4i8rU-SpI/AAAAAAAAK3w/Si2HJ8xPcHw2AmZwiFSj8cJm2i1H4_BrACLcBGAs/s1600/value-investing.jpeg',
          titulo: '10 Dicas de investimento para iniciantes.',
          url: '/site',
        },
        {
          imagem: 'https://instinctif.com/content/uploads/2016/05/financial-services.jpg',
          titulo: 'Metodologias de mercado para começar investindo bem.',
          url: '/site',
        },
        {
          imagem: 'https://foundersguide.com/wp-content/uploads/2020/08/finance1.jpg',
          titulo: 'Quero investir, por onde devo começar?',
          url: '/site',
        },
        {
          imagem: 'https://2.bp.blogspot.com/-6FTH4q4m93U/XL4i8rU-SpI/AAAAAAAAK3w/Si2HJ8xPcHw2AmZwiFSj8cJm2i1H4_BrACLcBGAs/s1600/value-investing.jpeg',
          titulo: '10 Dicas de investimento para iniciantes.',
          url: '/site',
        },
        {
          imagem: 'https://instinctif.com/content/uploads/2016/05/financial-services.jpg',
          titulo: 'Metodologias de mercado para começar investindo bem.',
          url: '/site',
        },
        {
          imagem: 'https://foundersguide.com/wp-content/uploads/2020/08/finance1.jpg',
          titulo: 'Quero investir, por onde devo começar?',
          url: '/site',
        },
      ])
    })
  },

  obterMaterias() {
    return new Promise(res => {
      res([
        {
          imagem: 'https://logodownload.org/wp-content/uploads/2018/08/valor-economico-logo-768x263.png',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
        {
          imagem: 'https://blog.bko.com.br/wp-content/uploads/2017/08/folha-de-sao-paulo-logo-768x116.jpg',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
        {
          imagem: 'https://blog.bko.com.br/wp-content/uploads/2017/08/folha-de-sao-paulo-logo-768x116.jpg',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
        {
          imagem: 'https://blog.bko.com.br/wp-content/uploads/2017/08/folha-de-sao-paulo-logo-768x116.jpg',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
        {
          imagem: 'https://logodownload.org/wp-content/uploads/2018/08/valor-economico-logo-768x263.png',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
        {
          imagem: 'https://blog.bko.com.br/wp-content/uploads/2017/08/folha-de-sao-paulo-logo-768x116.jpg',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
        {
          imagem: 'https://blog.bko.com.br/wp-content/uploads/2017/08/folha-de-sao-paulo-logo-768x116.jpg',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
        {
          imagem: 'https://blog.bko.com.br/wp-content/uploads/2017/08/folha-de-sao-paulo-logo-768x116.jpg',
          texto: 'Possui um modelo de negócio completamente diferenciado. Trazendo simplicidade e zero burocracias para que você comece a investir com valores muito mais acessíveis.',
          url: '/site',
        },
      ])
    })
  },

  obterPhotos() {
    return new Promise(res => {
      res([
        {
          imagem: '/images/person-02.png',
          nome: 'Leonardo Stollmeier',
          cargo: 'Cofounder',
          url: '/',
        },
        {
          imagem: '/images/person-01.jpg',
          nome: 'Isabela Tafner',
          cargo: 'Cofounder',
          url: '/',
        },
        {
          imagem: '/images/person-03.jpg',
          nome: 'Matheus Tafner',
          cargo: 'Cofounder',
          url: '/',
        },
      ])
    })
  },

  obterTestemunhos() {
    return new Promise(res => {
      res([
        {
          texto: 'Um modelo de negócio inovador que traz acessibilidade e grandes mudanças em como pensamos e investimos no mercado imobiliário.',
          imagem: 'mauro-fistarol.jpg',
          nome: 'Mauro Fistarol',
          cargo: 'Founder da Ivory Business Boutique de M&A',
          url: '/',
        },
        {
          texto: 'A Divvy abre um novo mercado cheio de oportunidades para corretores, construtoras e investidores de todo Brasil.',
          imagem: 'renato-monteiro.jpg',
          nome: 'Renato Monteiro',
          cargo: 'CEO da Fast Sale Tecnologia',
          url: '/',
        },
      ])
    })
  },
}
